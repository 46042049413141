import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import profileImg from "../images/about/about-profile@2x.png"
import angleRight from "../images/common/angle-right-white.svg"
import feather from "../images/home/feather.png"
import seoContent from "../helpers/seoContent"

const About = () => {
  const { siteDescriptions } = seoContent
  return (
    <Layout>
      <SEO title="About" description={siteDescriptions.default} />
      <div id="about">
        <div className="about-header">
          <h1>About</h1>
        </div>
        <div className="about-body">
          <div className="about-content-wrap">
            <div className="about-img-container">
              <img src={profileImg} alt="image of Gabriella" />
            </div>
            <div className="about-content">
              <div className="about-title">Hi, I’m Gabriella!</div>
              <div className="about-section">
                <div className="about-section-title">About My Poetry</div>
                <div className="about-section-text">
                  My debut poetry collection, “Healing”, was published in
                  February 2020 and is available on{" "}
                  <a
                    className="book-link"
                    href="https://www.amazon.com/Healing-collection-poems-Gabriella-Alziari/dp/0578616513/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Amazon
                  </a>
                  . I am so grateful for the support I’ve received worldwide.
                  Through my words, I hope to spread a message of
                  self-empowerment, showing my readers they are capable of
                  anything as long as they practice self-love.
                  <br />
                  <br />
                  You can see more of my poetry by following me on Instagram{" "}
                  <a
                    className="book-link"
                    href="https://www.instagram.com/gabriellaalziari/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    @gabriellaalziari
                  </a>
                  .
                </div>
              </div>
              <div className="about-section">
                <div className="about-section-title">About My Coaching</div>
                <div className="about-section-text">
                  I’m passionate about helping others develop their leadership
                  ability and channel their energy towards a greater purpose.
                  I’ve been in the coaching space for 5 years and have coached a
                  range of individuals, from executives to students.
                  <br />
                  <br />I work with my clients to manage their energy, trust
                  their intuition and lead a fulfilling life. I have a reiki
                  level 2 certification and am a practitioner of meditation,
                  yoga and qi gong. I use techniques from these disciplines to
                  reduce stress, maximize mental clarity, and promote
                  self-awareness.
                  {/* On my{" "}
                  <a
                    className="book-link"
                    href="https://www.youtube.com/channel/UCA700jkdvFRuhlCviRlnawA/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    YouTube
                  </a>{" "}
                  channel, you can find tools to get in touch with your
                  spirituality and balance your energy. */}
                  <br />
                  <br />
                  You can sign up for coaching, personal readings and guided
                  reiki sessions{" "}
                  <a
                    className="book-link"
                    href="https://alziari.co/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    here
                  </a>
                  .
                </div>
              </div>
              <div className="about-section">
                <div className="about-section-title">Education</div>
                <div className="about-section-text">
                  <ul>
                    <li>
                      Master of Science, Organizational Behavior - London School
                      of Economics
                    </li>
                    <li>
                      Study Abroad, English Literature - University of Oxford
                    </li>
                    <li>
                      Bachelor of Arts, English Literature - Kenyon College
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="about-healing">
            <div className="about-healing-content">
              <div className="about-healing-title">Healing</div>
              <a
                className="about-healing-btn"
                href="https://www.amazon.com/Healing-collection-poems-Gabriella-Alziari/dp/0578616513/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Now
                <img
                  className="arrow"
                  src={angleRight}
                  alt="angle-right-icon"
                />
              </a>
              <div className="about-healing-text">A collection of poems</div>
            </div>
          </div>
          <div className="about-follow">
            <div className="footer">
              <div className="learn-more section">
                <div className="title">Learn More</div>
                <div className="content">
                  <Link className="learn-btn" to="/faq">
                    <span>Read her FAQ</span>
                    <img
                      className="arrow"
                      src={angleRight}
                      alt="angle-right-icon"
                    />
                  </Link>
                  <Link className="learn-btn" to="/coaching">
                    <span>Explore Coaching</span>
                    <img
                      className="arrow"
                      src={angleRight}
                      alt="angle-right-icon"
                    />
                  </Link>
                  <Link className="learn-btn" to="/contact">
                    <span>Get in touch</span>
                    <img
                      className="arrow"
                      src={angleRight}
                      alt="angle-right-icon"
                    />
                  </Link>
                </div>
              </div>
              <div className="follow section">
                <div className="title">Follow Her</div>
                <div className="content">
                  <div className="social-media-wrap">
                    <a
                      className="social-media"
                      href="https://www.facebook.com/Gabriella-Alziari-533682247139232/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="social-media"
                      href="https://www.instagram.com/gabriellaalziari/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className="social-media"
                      href="https://www.linkedin.com/in/gabbyalziari/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <img className="feather" src={feather} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
